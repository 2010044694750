<template>
    <div ref="latestValue" class="latest-value animate__animated">
        <div class="latest-value__bg" :style="deviceImage">
            <div v-if="value !== null">
                <div v-if="currentMetric.getSlug() === 'smoke_status'"
                     class="d-flex align-items-center justify-content-center latest-value__status">
                    <div class="text-success" v-if="intValue === 0">{{ $t('Все ОК') }}</div>
                    <div class="text-danger" v-else-if="intValue === 1">{{ $t('Внимание! Дым!') }}</div>
                    <div v-else-if="intValue === 8">{{ $t('Тестирование') }}</div>
                </div>
                <div v-else>
                    <div class="latest-value__value">{{ Math.round(value) }}</div>
                    <div class="latest-value__units">{{ units }}</div>
                </div>
            </div>
            <div v-else>
                <div class="latest-value__value">N/A</div>
            </div>
        </div>
    </div>
</template>

<script>

import DefaultCard_1LatestValue from "../default/DefaultCard_1LatestValue";

export default {
    name: "SmokeCard_1LatestValue",
    extends: DefaultCard_1LatestValue,
    computed: {
        deviceImage() {
            const deviceSlug = this.deviceObject?.getType()?.getSlug();
            let iconFile;
            try {
                if (this.currentMetric.getSlug() === 'smoke_status') {
                    if (this.intValue === 0) {
                        iconFile = require(`../../../assets/metrics/all_ok.png`);
                    } else if (this.intValue === 1) {
                        iconFile = require(`../../../assets/metrics/fire.png`);
                    } else if (this.intValue === 8) {
                        iconFile = require(`../../../assets/metrics/test.png`);
                    }
                } else {
                    iconFile = require(`../../../assets/metrics/${this.currentMetric?.getIcon()}.png`);
                }

                return {
                    "background-image": `url(${iconFile})`
                }
            } catch (e) {
                return null;
            }
        },
    },
}
</script>

<style lang="less" scoped>
@import "~animate.css";

.latest-value {
    width: 100%;
    padding: 0;

    &__bg {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        width: 170px;
        height: 170px;
        background-color: #ccc;
        border-radius: 100%;
        color: #fff;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    &__value {
        text-align: center;
        font-weight: 700;
        font-size: 1.75rem;
        line-height: 1;
        color: #fff;
    }

    &__units {
        text-align: center;
        font-weight: 500;
        font-size: 1.2rem;
        color: #fff;
    }

    &__status {
        background: #FFFFFF;
        border-radius: 10px;
        height: 40px;
        text-align: center;
        padding: 0 10px;
        font-weight: 500;
        font-size: 13px;
        color: #61616A;
    }
}
</style>
