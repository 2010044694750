<script>

import Icon from "../../Icon";
import DefaultCard_1Header from "../default/DefaultCard_1Header";
import DefaultCard_3 from "../default/DefaultCard_3";
import SmokeCard_1Table from "./SmokeCard_1Table";
import SmokeCard_3ValuesList from "./SmokeCard_3ValuesList";

export default {
    name: "SmokeCard_3",
    extends: DefaultCard_3,
    components: {
        HeaderComponent: DefaultCard_1Header,
        ValuesListComponent: SmokeCard_3ValuesList,
        ChartComponent: SmokeCard_1Table,
        Icon
    },
}
</script>
