<script>

import Icon from "../../Icon";
import DefaultCard_1MetricsButtons from "../default/DefaultCard_1MetricsButtons";
import SmokeCard_1LatestValue from "./SmokeCard_1LatestValue";
import SmokeCard_1Button from "./SmokeCard_1Button";
import DefaultCard_1Header from "../default/DefaultCard_1Header";
import DefaultCard_1 from "../default/DefaultCard_1";
import SmokeCard_1Table from "./SmokeCard_1Table";

export default {
    name: "SmokeCard_1",
    extends: DefaultCard_1,
    components: {
        HeaderComponent: DefaultCard_1Header,
        MetricsButtonsComponent: SmokeCard_1Button,
        LatestValueComponent: SmokeCard_1LatestValue,
        ChartComponent: SmokeCard_1Table,
        Icon
    }
}
</script>
