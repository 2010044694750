<script>

import Icon from "../../Icon";
import DefaultCard_1Header from "../default/DefaultCard_1Header";
import DefaultCard_2 from "../default/DefaultCard_2";
import SmokeCard_1Table from "./SmokeCard_1Table";
import SmokeCard_2LatestValue from "./SmokeCard_2LatestValue";
import SmokeCard_2MetricsButtons from "./SmokeCard_2MetricsButtons";

export default {
    name: "SmokeCard_2",
    extends: DefaultCard_2,
    components: {
        HeaderComponent: DefaultCard_1Header,
        MetricsButtonsComponent: SmokeCard_2MetricsButtons,
        LatestValueComponent: SmokeCard_2LatestValue,
        ChartComponent: SmokeCard_1Table,
        Icon
    },
    computed: {
        metricImage() {
            const deviceSlug = this.deviceObject?.getType()?.getSlug();
            const intValue = this.timeseries?.find(item => item.getSlug() === 'smoke_status')?.getLatestData()?.getValue();
            let iconFile;
            try {
                if (this.currentMetric.getSlug() === 'smoke_status') {
                    if (intValue === 0) {
                        iconFile = require(`../../../assets/metrics/all_ok.png`);
                    } else if (intValue === 1) {
                        iconFile = require(`../../../assets/metrics/fire.png`);
                    } else if (intValue === 8) {
                        iconFile = require(`../../../assets/metrics/test.png`);
                    }
                } else {
                    iconFile = require(`../../../assets/metrics/${this.currentMetric?.getIcon()}.png`);
                }

                return {
                    "background-image": `url(${iconFile})`
                }
            } catch (e) {
                return null;
            }
        },
    }

}
</script>
