<script>
import SmokeCard_1 from "./SmokeCard_1";
import SmokeCard_2 from "./SmokeCard_2";
import SmokeCard_3 from "./SmokeCard_3";
import AbstractDefaultCard from "../default/AbstractDefaultCard";

export default {
    name: "AbstractSmokeCard",
    extends: AbstractDefaultCard,
    components: {
        SmokeCard_1,
        SmokeCard_2,
        SmokeCard_3,
    },
    computed: {
        currentCardComponent() {
            switch (this.currentCardDesign) {
                case 3:
                    return SmokeCard_3;
                case 2:
                    return SmokeCard_2;
                default:
                    return SmokeCard_1
            }
        }
    },
}
</script>
